import React from 'react'

const TermAndConditions = () => {
    return (
        <>
            <section className='terms'>
                <div className="container">
                    <h1>Terms and Conditions</h1>
                    <p>Welcome to Simfly! By accessing or using our website (<a href='https://simfly.biz/'>https://simfly.biz/</a>) and services, you agree to comply with and be bound by the following terms and conditions. Please read these terms carefully before using our services. If you do not agree with any part of these terms, you may not access or use our services.</p>
                    <br />
                    <ol type='1'>
                        <li><h5 className='d-inline-block'>Definitions</h5>
                            <ol className='ps-3' type='a'>
                                <li>"Simfly" refers to the online platform providing global eSIM services for travelers.</li>
                                <li>"User" refers to any individual or entity accessing or using the Simfly website or services.</li>
                                <li>"eSIM" refers to the embedded SIM technology provided by Simfly for connectivity purposes.</li>
                            </ol>
                        </li>
                        <li className='mt-4'><h5 className='d-inline-block'>Eligibility</h5>
                            <ol className='ps-3' type='a'>
                                <li>Individuals must be more than 18 years of age or have the legal capacity to enter into a binding agreement in their jurisdiction.</li>
                                <li>Through your use of our services, it will be understood that all information given is complete and current.</li>
                            </ol>
                        </li>
                        <li className='mt-4'><h5 className='d-inline-block'>Services</h5>
                            <ol className='ps-3' type='a'>
                                <li>Simfly is an eSIM services provider that allows customers to travel with an active connection in over 150+ countries with the help of different data plans.</li>
                                <li>Availability of the eSIM service and network coverage apply to all the mentioned services.</li>
                                <li>Simfly also accepts the right to alter or remove parts of the provided services without the clients receiving a prior notice.</li>
                            </ol>
                        </li>
                        <li className='mt-4'><h5 className='d-inline-block'>User Responsibilities</h5>
                            <ol className='ps-3' type='a'>
                                <li>On the eSIM technology the client has the responsibility of making sure that their device is compatible with the eSIM technology.</li>
                                <li>According to the instructions posted by Simfly users are required to follow the installation and activation procedures.</li>
                                <li>Regarding legal requirements of the country that a user is in, users consent to, use of simfly services shall follow such laws.</li>
                            </ol>
                        </li>
                        <li className='mt-4'><h5 className='d-inline-block'>Payments and Refunds</h5>
                            <ol className='ps-3' type='a'>
                                <li>All payments for Simfly services must be made in advance.</li>
                                <li>Prices and available data plans are subject to change without notice.</li>
                                <li>Refunds are only issued in cases where the service cannot be activated due to technical issues on Simfly’s end. Refund requests must be submitted within 14 days of purchase.
                                </li>
                            </ol>
                        </li>
                        <li className='mt-4'><h5 className='d-inline-block'>Limitation of Liability</h5>
                            <ol className='ps-3' type='a'>
                                <li>Simfly is not liable for:
                                    <ul>
                                        <li>Connectivity issues caused by third-party network providers</li>
                                        <li>Service interruptions due to factors beyond our control, such as natural disasters or government restrictions. 6.2. Customers understand that its Simfly services are provided ‘as is’ without any warranties of any kind.</li>
                                    </ul>
                                </li>
                            </ol>
                        </li>
                        <li className='mt-4'><h5 className='d-inline-block'>Privacy Policy</h5>
                            <ol className='ps-3' type='a'>
                                <li>Simfly respects user privacy and handles personal data in accordance with our Privacy Policy.</li>
                                <li>By using our services, you consent to the collection and use of your data as outlined in our Privacy Policy.</li>
                            </ol>
                        </li>
                        <li className='mt-4'><h5 className='d-inline-block'>Intellectual Property</h5>
                            <p> Every page of the Simfly website, its content, trademarks and materials are subject to the intellectual property rights of Simfly company. Customers are also prohibited from downloading, replicating or transmitting part or all Simfly content without express permission from the content owner.
                            </p>
                        </li>
                    </ol>
                </div>
            </section>
        </>
    )
}

export default TermAndConditions