import { useEffect, useRef } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { ApiCall } from "../../EnvConfig/env_config";

const LiveSearchBar = () => {
    const [search, setSearch] = useState("");
    const [searchData, setSearchData] = useState([]);
    const [selectedItem, setselectedItem] = useState(-1);
    const [suggestionData, setSuggestionData] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();

    const handleChange = async (e) => {
        await setSearch(e.target.value);
        //if (searchData === '') setSuggestionData(false)
        //else setSuggestionData(true)
    };

    const handleClose = () => {
        setSearch("");
        setSearchData([]);
        setselectedItem(-1)
        setSuggestionData(false)
    };

    const handleKeyDown = (e) => {
        if (selectedItem < searchData.length) {
            if (e.key === "ArrowUp" && selectedItem > 0) {
                setselectedItem((prev) => prev - 1);
            } else if (
                e.key === "ArrowDown" &&
                selectedItem < searchData.length - 1
            ) {
                setselectedItem((prev) => prev + 1);

            } else if (e.key === "Enter") {
                if (selectedItem >= 0) {
                    setSuggestionData(false)
                    setSearch(searchData[selectedItem].value)
                    navigate(`/plans/${searchData[selectedItem].value}`)
                } else {
                    setSuggestionData(false)
                    if (search.trim('') === '') {
                        toast.error("Please Search Something")
                        return;
                    }
                    navigate(`/plans/${search}`)
                }
            }
        } else {
            setselectedItem(-1)
        }
    };

    const handleClick = (value) => {
        navigate(`/plans/${value}`)
        setSearch(value)
        setSuggestionData(false)
    }
    function truncateText(text, maxLength) {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        } else {
            return text;
        }
    }

    const getDataApiCall = async (search) => {
        let GetSearchingdata = await ApiCall(`/api/Web/GetSearchingData`, { PrefixText: search ? search : "" })
        if (GetSearchingdata.status === 'SUCCESS') {
            setSearchData(GetSearchingdata?.searchingDetails);
            setSuggestionData(true)
        } else {
            setSearchData([]);
            setSuggestionData(false)
        };
    }

    useEffect(() => {
        if (search === '') {
            setselectedItem(-1)
            setSuggestionData(false)
            setSearchData([]);
            return;
        }
        const getData = setTimeout(() => {
            getDataApiCall(search)
        }, 500)
        return () => clearTimeout(getData)
    }, [search]);

    const searchBoxRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
                setSuggestionData(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const pathnameWithSearch = location.pathname + location.search;
        if (!pathnameWithSearch.includes('?searchingText')) {
            setSearch('')
        }
    }, [location.pathname, location.search]);

    return (
        <div className="w-100">
            <div ref={searchBoxRef} className=' srch_box_input d-flex justify-content-center align-items-center'>
                <div style={{ width: "100%" }}>
                    <input
                        autoComplete="off"
                        type="text"
                        className='search_input form-control'
                        onChange={(e) => handleChange(e)}
                        value={truncateText(search, 50)}
                        onKeyDown={handleKeyDown}
                        placeholder="Search your Country.."
                    />
                    {
                        search.trim("").length > 0 &&
                        <button style={{ position: "absolute", top: "45%", right: "0", transform: "translate(-50%, -50%)", backgroundColor: "inherit", border: "none", fontSize: "28px" }} onClick={handleClose}><close /></button>
                    }
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                    {
                        search.trim("").length > 0 &&
                        <button
                                className="border-0 bg-transparent fw-bold text-black-50 search_close"
                                onClick={() => setSearch('') }
                        >
                              <i class="bi bi-x"></i>
                        </button>
                    }
                   
                    <button
                        className="mybtn secondary-bg text-dark"
                        type="button"
                        id="button-addon2"
                        onClick={() => search.trim('') !== '' ? navigate(`/plans/${search}`) : toast.error("Please Search Something")}
                    >
                        Search
                    </button>
                </div>
               

                {suggestionData === true && searchData && (
                    <div
                        className='srch_box position-absolute top-100'
                        style={{ cursor: "pointer", overflowY: 'auto', maxHeight: '22rem', marginTop: "5px", zIndex:"9999" }}
                    >
                        {searchData?.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    onClick={() => handleClick(item.value)}
                                    className={`d-flex gap-2 py-1 srch_box_item ${selectedItem === index ? "srch_box_item active"
                                        : "srch_box_item"}`}
                                >
                                    <i class="bi bi-search text-black-50"></i>  {item.value}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}
export default LiveSearchBar;                                